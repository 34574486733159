<template>
  <v-btn
    class="flower-button violet--text"
    color="#fce8e0" x-large
    @click="$emit('click')">
    <v-progress-circular
      v-if="isLoading"
      size="30"
      indeterminate
      color="primary"
      class="mr-5"
    />
    {{ label }}
    <v-img
      height="32"
      class="ml-5"
      width="32"
      src="../assets/FlowerCircle.svg"
    />
  </v-btn>
</template>

<script>
export default {
  name: 'FlowerButton',
  props: ['label', 'isLoading'],
};
</script>
