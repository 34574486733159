<template>
  <div>
    <section id="hero" class="section-container">
      <v-row align="stretch">
        <v-col cols="12" lg="6" xl="6" md="6">
          <v-row>
            <v-col cols="12">
              <div class="box-with-flower">
                <div class="text-h5 primary--text bg-purple text-center">
                  {{ $t('home.sophia.welcomeText')}}
                </div>
                <img class="hidden-md-and-down" src="../assets/flower_bigflower_big.svg" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <img class="sophia" src="../assets/Sophia_Neutral.svg" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" xl="6" md="6"
          class="text-center"
          style="padding: 20px"
        >
            <a :href="$t('home.videos.video1')" target="_blank" nofollow>
            <img src="../assets/video.png" style="width: 100%" />
          </a>
          <a
            href="https://app.skillosophia.com/"
            target="_blank" nofollow
          >
            <img src="../assets/Start button 2 hover.svg" style="margin-top: 80px" />
          </a>
          <!-- <iframe style="border: 8px solid #EB6510;" width="90%" height="100%" src="https://www.youtube.com/embed/K0fJSrXZqW8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
        </v-col>
      </v-row>
    </section>
    <section id="about" class="section-container">
      <v-row align="stretch" class="text-padding about-text-layer" style="position: relative">
        <v-col cols="12" lg="8" md="12" sm="12" xl="8">
        <div class="display-2 mb-3 primary--text">
          {{ $t('home.what.title') }}
        </div>
        <div class="what-is-skillosophia">
          <p>
            {{ $t('home.what.summary') }}
          </p>
          <ul>
            <li v-for="key in Object.keys(localeContent.home.what.items)"
              :key="key">
              {{ $t(`home.what.items.${key}`) }}
            </li>
          </ul>
        </div>
        </v-col>
        <v-col cols="12" lg="4" md="12" sm="12" xl="4">
            <a :href="$t('home.videos.video2')" target="_blank" nofollow>
            <v-img contain src="../assets/video2.svg" />
          </a>
        </v-col>
      </v-row>
      <div class="hidden-md-and-down about-orange-block">
      </div>
    </section>
    <section id="our-philosophy" class="section-container">
        <v-row no-gutters>
          <v-col cols="12" md="12" lg="8" xl="8">
            <div class="display-2 mb-3 primary--text">
              {{ $t('home.philosophy.title-1') }}
            </div>
            <div class="our-philosophy-text">
              <div class="display-2 mb-3 white--text">
                {{ $t('home.philosophy.title-2') }}
              </div>
              <p v-html="$t('home.philosophy.intro')" />
              <p v-for="key in Object.keys(localeContent.home.philosophy.items)"
                :key="key"
              >
                <b>{{ $t(`home.philosophy.items.${key}.title`) }}:</b>
                {{ $t(`home.philosophy.items.${key}.content`) }}
              </p>
              <img
                class="hidden-lg-and-up hidden-sm-and-down our-philosophy-flower"
                src="../assets/flower_bigflower_big.svg"
              />
            </div>
          </v-col>
          <v-col cols="4 hidden-md-and-down">
            <v-img src="../assets/philiosophy background.png" contain />
          </v-col>
        </v-row>
    </section>
    <section id="about-us" class="section-container">
      <v-container>
        <div class="display-2 mb-3 primary--text">
          {{ $t('home.team.title') }}
        </div>
        <v-row no-gutters class="center-on-sm-and-down">
          <v-col
            cols="12" sm="6" xs="12" lg="4" md="6" xl="3"
            class="cursor-pointer "
            v-for="(member, index) in staffMembers"
            :key="`staff-member-${index}`"
            @click="toggleStaff(member)">
            <StaffCard :staffMember="member" />
          </v-col>
        </v-row>
        <v-dialog
          v-model="staffDialog"
          transition="dialog-transition"
        >
          <staff-popup :staffMember="staffMember" @close="staffDialog = false" />
        </v-dialog>
      <div class="py-12"></div>
      </v-container>
    </section>
    <section id="contact-us">
        <div class="display-2 mb-3 primary--text">
          {{ $t('home.contactUs.title') }}
        </div>
      <v-row>
        <v-col class="hidden-md-and-down" cols="3">
          <div class="contact-us-left">
            <img src="../assets/flower_bigflower_big.svg" />
          </div>
        </v-col>
        <v-col cols="12" lg="6" xl="6" class="contact-us-form-col">
          <form-contact-us />
        </v-col>
        <v-col class="hidden-md-and-down" cols="3">
          <div style="position: relative">
          <div class="contact-us-right">
          </div>
          </div>
        </v-col>
      </v-row>
    </section>
    <section id="social" class="section-container mt-10">
      <Instagram />
    </section>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import VueInstagram from 'vue-instagram';
import FormContactUs from '../components/FormContactUs.vue';
import Instagram from '../components/Instagram.vue';
import StaffCard from '../components/StaffCard.vue';
import StaffPopup from '../components/StaffPopup.vue';

import './menu.css';
import './site.css';

// @ is an alias to /src
export default {
  components: {
    FormContactUs, Instagram, StaffCard, StaffPopup,
  },
  name: 'Home',
  data() {
    return {
      // philosophyBackground: require('../assets/Background_Website_Ourphilosophy.svg'),
      // contactUs: require('../assets/Background_Website_Contactnewsletter.svg'),
      staffDialog: false,
      orangeBlock: require('../assets/orange-block.png'),
      videoIcon: require('../assets/video-icon.png'),
      staffMember: undefined,
      staffState: 'list',
    };
  },
  computed: {
    localeContent() {
      const { $i18n } = this;
      return $i18n.messages[$i18n.fallbackLocale];
    },
    staffMembers() {
      return [{
        name: this.$t('home.team.catherine.name'),
        title: this.$t('home.team.catherine.title'),
        img: require('../assets/Catherine-ai.png'),
        bio: this.$t('home.team.catherine.bio'),
      }, {
        name: this.$t('home.team.grégoire.name'),
        title: this.$t('home.team.grégoire.title'),
        img: require('../assets/Grégoire-ai.png'),
        bio: this.$t('home.team.grégoire.bio'),
      }, {
        name: this.$t('home.team.leonie.name'),
        title: this.$t('home.team.leonie.title'),
        img: require('../assets/Leonie-ai.png'),
        bio: this.$t('home.team.leonie.bio'),
      }, {
        name: this.$t('home.team.nino.name'),
        title: this.$t('home.team.nino.title'),
        img: require('../assets/nino-ai.png'),
        bio: this.$t('home.team.nino.bio'),
      }, {
        name: this.$t('home.team.bérénice.name'),
        title: this.$t('home.team.bérénice.title'),
        img: require('../assets/Bérénice-ai.png'),
        bio: this.$t('home.team.bérénice.bio'),
      }, {
        name: this.$t('home.team.mia.name'),
        title: this.$t('home.team.mia.title'),
        img: require('../assets/Mia-ai.png'),
        bio: this.$t('home.team.mia.bio'),
      }, {
        name: this.$t('home.team.craig.name'),
        title: this.$t('home.team.craig.title'),
        img: require('../assets/craig-ai.png'),
        bio: this.$t('home.team.craig.bio'),
      }, {
        name: this.$t('home.team.nadja.name'),
        title: this.$t('home.team.nadja.title'),
        img: require('../assets/nadja-ai.png'),
        bio: this.$t('home.team.nadja.bio'),
      }, {
        name: this.$t('home.team.sophia.name'),
        title: this.$t('home.team.sophia.title'),
        img: require('../assets/skillosophia-ai.png'),
        bio: this.$t('home.team.sophia.bio'),
      }];
    },
  },
  methods: {
    toggleStaff(staffMember) {
      this.staffMember = staffMember;
      this.staffDialog = true;
    },
  },
};
</script>
