<template>
  <v-card style="background-color: #896783">
    <v-card-text>
      <v-row v-if="staffMember">
        <v-col cols="12" xl="8" lg="8">
          <div class="display-2 white--text">
            {{ staffMember.name }}
          </div>
          <div class="display-1 white--text">
            {{ staffMember.title }}
          </div>
          <p class="white--text" v-html="staffMember.bio" />
          <div class="text-right">
            <flower-button @click="$emit('close')" :label="$t('home.team.close')" />
          </div>
        </v-col>
        <v-col cols="12" xl="4" lg="4">
          <v-avatar size="256">
            <img
              class="bg-white"
              alt="user"
              :src="staffMember.img"
            >
          </v-avatar>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import FlowerButton from './FlowerButton.vue';

export default {
  components: { FlowerButton },
  name: 'StaffPopup.vue',
  props: ['staffMember'],
};
</script>
