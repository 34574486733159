<template>
  <v-form class="contact-us-form" ref="form">
    <v-row>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-text-field
          class="form-field"
          :rules="rules.required"
          background-color="white"
          :placeholder="$t('home.contactUs.form.name')"
          v-model="form.name"
        >
          <template v-slot:prepend>
            <img src="../assets/contact-us-icon.png" >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-text-field
          class="form-field"
          background-color="white"
          :placeholder="$t('home.contactUs.form.surname')"
          v-model="form.surname"
        >
          <template v-slot:prepend>
            <img src="../assets/contact-us-icon.png" >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          class="form-field"
          :rules="rules.required"
          :placeholder="$t('home.contactUs.form.email')"
          v-model="form.email"
        >
          <template v-slot:prepend>
            <img src="../assets/contact-us-icon.png" >
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          class="form-field"
          :placeholder="$t('home.contactUs.form.message')"
          v-model="form.message"
        >
        </v-textarea>
      </v-col>
    </v-row>
    <div class="text-h5">
      <v-checkbox
        color="white"
        class="white--text form-checkbox"
        :label="$t('home.contactUs.form.newsletter')"
        v-model="form.hasNewsletter">
      </v-checkbox>
    </div>
    <div class="text-h5">
      <v-checkbox
        color="white"
        class="white--text form-checkbox"
        :label="$t('home.contactUs.form.privacy')"
        :rules="rules.required"
        v-model="form.hasAgreedToPrivacy">
      </v-checkbox>
    </div>
    <div class="d-block text-right">
      <flower-button :isLoading="isLoading" :label="$t('home.contactUs.form.send')" @click="send" />
    </div>
    <v-alert class="mt-5 violet--text" v-if="isSent" color="#fce8e0">
      {{ $t('home.contactUs.form.sentSuccess') }}
    </v-alert>
    <v-alert class="mt-5" v-if="messages.length > 0" type="warning">
      <p v-for="(message, index) in messages" :key="`${index}-message`" />
    </v-alert>
  </v-form>
</template>

<script>
import FlowerButton from './FlowerButton.vue';

export default {
  name: 'FormContactUs',
  components: { FlowerButton },
  data() {
    return {
      form: {
        name: '',
        surname: '',
        email: '',
        message: '',
        hasNewsletter: true,
        hasAgreedToPrivacy: false,
      },
      isLoading: false,
      isSent: false,
      messages: [],
      rules: {
        required: [
          (v) => !!v || this.$t('home.contactUs.form.required'),
        ],
      },
    };
  },
  methods: {
    /**
     * Executes send
     */
    async send() {
      if (this.$refs.form.validate()) {
        const { form } = this;
        this.messages = [];
        try {
          this.isLoading = true;
          this.isSent = false;
          await this.$http.post('/odata/saleLeads', form);
          this.isSent = true;
        } catch (error) {
          this.messages.push(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
