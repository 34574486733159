<template>
  <div ref="instagramFeed">
    <div class="embedsocial-hashtag" data-ref="b65f42226ee580385b3dc2dd06f209565481c867"></div>
    <!-- built files will be auto injected -->
  </div>
</template>

<script>
/* eslint-disable max-len */

export default {
  mounted() {
    const el = document.createElement('script');
    el.innerHTML = '(function(d, s, id){var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js);}(document, "script", "EmbedSocialHashtagScript"));';
    this.$refs.instagramFeed.appendChild(el);
  },
};
</script>
