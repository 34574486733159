<template>
  <div class="mt-10">
    <v-avatar size="256">
      <img
        class="bg-white"
        alt="user"
        :src="staffMember.img"
      >
    </v-avatar>
    <h4 class="primary--text">{{ staffMember.name }}</h4>
    <h5 class="primary--text">{{ staffMember.title }}</h5>
  </div>
</template>

<script>
export default {
  name: 'StaffCard',
  props: ['staffMember'],
};
</script>
<style scoped>
.bg-white {
  background-color: white;
}
</style>
